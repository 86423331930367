import React from "react"
import { Link } from "gatsby"
import ArrowNext from "../images/logos/arrowNext.svg"

function NextArrow(props) {
  const { setCount, count, slideInfo } = props
  /* function next() {
    if (count === slideInfo.length - 1) {
      setCount(0)
      console.log(count)
    } else {
      setCount(count + 1)
    }

    console.log("slideInfo", slideInfo[count])
    console.log(count)
  } */
  const { className, style, onClick } = props
  return (
    <img
      className={className}
      style={{ ...style, display: "block", width: "5.5%" }}
      onClick={onClick}
      src={ArrowNext}
    />
  )
}

export default NextArrow
