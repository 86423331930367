import React from "react"
import { graphql } from "gatsby"
import SingleVariety from "../components/singleVariety"

const Variedades = ({ data, path }) => {
  const variedad = data.varieties
  return (
    <div>
      <SingleVariety variedad={variedad} path={path} data={data} />
    </div>
  )
}

export default Variedades

export const query = graphql`
  query($slug: String) {
    varieties: beer(slug: { eq: $slug }) {
      bitter
      canImg
      characteristics
      description
      grad
      id
      slug
      logo
      pairing
      name
      temperature
      pairingBack
      order
      buyLink
    }
    bock: file(relativePath: { eq: "bockmaridaje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cerocero: file(relativePath: { eq: "00maridaje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    clasica: file(relativePath: { eq: "clasicamaridaje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    redlager: file(relativePath: { eq: "redlagermaridaje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stout: file(relativePath: { eq: "stoutmaridaje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allBeer {
      nodes {
        bitter
        canImg
        characteristics
        description
        grad
        homeBackImg
        homeBackImgMobile
        id
        logo
        name
        pairing
        pairingBack
        pairingBackMobile
        slug
        temperature
        order
        buyLink
      }
    }
    allBeer {
      edges {
        node {
          id
          order
          pairing_back_image {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pairing_back_image_mobile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
/* allListJson {
  nodes {
    id
    bitter
    canImg
    characteristics
    description
    grad
    logo
    name
    pairing
    singleVarietyImg
    slideImg
    slug
    temperature
    pairingBackResp
  }
} */
