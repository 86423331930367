import React, { useState } from "react"
import styles from "../styles/singleVariety.module.css"
import { useMediaQuery } from "react-responsive"
import FlechaSelected from "../images/logos/flechaSelected.svg"
import { Link } from "gatsby"

const BeerMenuItem = ({ beer, path }) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  let link = `/${encodeURI(beer.slug)}`
  const activeLata = link === path
  return (
    <>
      <Link
        style={{ textDecoration: "none" }}
        to={link}
        className={styles.beerMenu}
      >
        <img
          className={activeLata ? styles.flechaSelected : styles.hidden}
          src={FlechaSelected}
        />
        <img id={styles.menuImg} src={beer.canImg} />
        <h3 className={activeLata ? styles.beerNameActive : styles.beerName}>
          {beer.name.toUpperCase()}
        </h3>
      </Link>
    </>
  )
}

export default BeerMenuItem
